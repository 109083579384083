.tool {
    width: 100%;
    min-width: 1200px;
}

.tool-container {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
}

.projects {
    margin: 1rem auto 1rem;
    width: 25%;
}

.advanced-options {
    height: 120px;
    display: flex;
    flex-direction: row;
    background: rgba(217, 217, 217, 0.22);
    width: 95%;
    padding: 20px;
    margin: 5px auto;
}

.advanced-options-slider-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
}

.advanced-options-slider-header {
    font-size: 24px;
}

.advanced-options-container {
    width: 95%;
    margin: 20px auto;
}

.arrow-btn {
    border: none;
    cursor: pointer;
    background: none;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-top: 14px solid rgba(103, 103, 103, 1);
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 14px solid rgba(103, 103, 103, 1);
}


.select-section {
    width: 40%;
    margin-left: 7.5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}

.select-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select-block > div {
    width: 50%;
}



.harmonize-button {
    margin: 0 30%;
    cursor: pointer;
    height: 60px;
    width: 40%;
    border-radius: .2rem;
    background: #294956;
    color: #fff;
    border: none;
    font-size: 24px;
}

.warning-text {
    color: #E41D1D;
    text-align: center;
    font-size: 20px;
}

.download-excel {
    display: flex;
    gap: 1rem;
    align-items: center;
    background: rgba(1, 1, 1, .06);
    width: max-content;
    margin: 20px auto;
    padding: 5px;
    border-radius: .2rem;
}

.downloadable:hover{
    cursor: pointer;
}

.blocked:hover{
    cursor: not-allowed;
}

.dots {
  display:inline-block;
  clip-path: inset(0 12px 0 0);
  animation: l 2s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -4px 0 0)
  }
}
.process-section {
    width: 50%;
}

.section-header {
    text-align: center;
}

.harmonization-section {

}

.arrow-left {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    border-right: 10px solid #9d9f9f;
    display:inline-block;
    position:absolute;
    margin-top: -4px;
}

.tooltip-atom {
    z-index: 1;
    margin-top: -30px;
    margin-left: 10px;
    max-width: 600px;
    min-width: 200px;
    background: #9d9f9f;
    display: inline-block;
    position: absolute;
    padding: 10px;
    border-radius: .25rem;
}

.file-name {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
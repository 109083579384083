.harmonization-header {
    margin: 20px 0 20px 5%;
}

.harmonization-dgrndrop {
    display: flex;
    justify-content: space-evenly;
}

.fields-table::-webkit-scrollbar {
    width: .5em;
    height: .6rem;
}

.fields-table::-webkit-scrollbar-thumb {
    background-color: #294956;
    border-radius: 1rem;
}

.fields-cell::-webkit-scrollbar {
    width: .5em;
    height: .6rem;
    display: none;
}

.fields-cell:hover::-webkit-scrollbar {
    display: block;
}


.fields-cell::-webkit-scrollbar-thumb {
    background-color: #294956;
    border-radius: 1rem;
}

progress {
    height: 8px;
    border-radius: 2rem;
    border: 1px solid #B2B2B2;
    border-left: none;
    background: none;
}

progress::-webkit-progress-value {
    border-radius: 1.5rem;

    background: #294956;
}

progress::-webkit-progress-bar {
    border-radius: 1.5rem;

    background: #EFEFEF;
}
.upload-container {
    width: 35%;
    display: flex;
    flex-direction: column;
    margin: auto;
    min-width: 800px;
}

.upload-header {
    display: flex;
    justify-content: center;
}

.select-block-column {
    display: flex;
    flex-direction: column;
    width:90%;
    margin: auto;
    height: 250px;
    justify-content: space-evenly;
}

.select-sub-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.select-system-key {
    width: 45%;
}

.select-sub-block-header {
    font-size: 20px;
}

.select-sub-block-container {
    width: 60%;
}

.sidebar {
    margin: 1rem 0 1rem 0;
    border-right: 0.2rem solid lightgrey;
    min-width: 20rem;
}

.atom-logo {
    padding: 1rem 0 0 3rem;
    width: 230px;
}

.btn {
    padding: 0 0 0 1rem;
    font-size: 1em;
    color: #1d4956;
    align-items: center;
    display: flex;
    min-height: 40px;
    max-height: 40px;
    min-width: 150px;
    border: none;
    background-color: transparent;
    text-decoration: none;
}

.btn:hover {
    color: #ef7d0b;
    cursor: pointer;
}

.btn-icon {
    margin-right: 1rem;
    width: 16px;
}

ul {
    list-style-type: none;
}
